import React from 'react';
import ReactDOM from 'react-dom';
import * as lb from './lambda.js';
import './css/easyadapter.css';
import { Spinner } from "./spinner";

import AWS from 'aws-sdk';
import { ua, au } from './ac';

export class ChangePassForm extends React.Component {

  lambdaMessageCallback(message, checkUser, checkChanged) {

    return function(err, data){
        
        if (!err) alert("Done"); else alert(err);

    }

  }

  constructor(props) {

    super(props);

    console.log(props)
    if(props.loginData && props.loginData.userToken && props.loginData.userToken != "Not logged in" && props.loginData.userToken != undefined){
        //AWS.config.credentials = au(props.loginData);
    } else {
      this.props.history.push("/login");  
    }

    this.state = {
      email: '',
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      accountid:props?.loginData?.userUid
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(event) {
    this.setState({[event.target.id]: event.target.value});
  }

  handleSubmit(event) {
    lb.lambdaInvoke('SubNinjas_ChangePass', this.state, this.lambdaMessageCallback('Password changed', false, true).bind(this));
    event.preventDefault();
  }

  render() {

    const isEnabled = this.state.email.length > 0 && this.state.newPassword.length > 0 && (this.state.newPassword === this.state.newPassword2);

    return (
      <form onSubmit={this.handleSubmit}>

      <br/>
      <br/>
      <br/>

      <table><tbody>
        
        <tr><td className="sn-col-1">
          <label>Email</label></td><td>
          <input type="text" id="email" value={this.state.email} onChange={this.handleChange} /><br/>
        </td></tr>  

        <tr><td>
          <label>Old Password</label></td><td>
          <input type="password" id="oldPassword" value={this.state.oldPassword} onChange={this.handleChange} /><br/>    
        </td></tr>   

        <tr><td>        
          <label>New Password</label></td><td>
          <input type="password" id="newPassword" value={this.state.newPassword} onChange={this.handleChange} /><br/> 
        </td></tr> 

        <tr><td>        
          <label>New Password</label></td><td>
          <input type="password" id="newPassword2" value={this.state.newPassword2} onChange={this.handleChange} /><br/> 
        </td></tr> 



        </tbody></table>

        <br/>

        <input type="submit" value="Change Password" disabled={!isEnabled} />

      </form>
    );
  }

}