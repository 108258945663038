import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import * as lb from './lambda.js';
import './css/easyadapter.css';
import { Spinner } from "./spinner";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import AWS from 'aws-sdk';
import { ua, au } from './ac';
AWS.config.region = 'eu-west-1';

export class TransformersListForm extends React.Component {

  lambdaMessageCallback(message, checkUser, checkChanged) {

    return (err, data)=>{

      if (err){
        this.props.history.push("/login"); 
        return; 
      } else {

        var output = JSON.parse(data.Payload);
        var data = JSON.parse(output.body);

        let datax = data.map( b => {
          b.gameFinishedSampleTriggers = JSON.stringify(b.gameFinishedSampleTriggers);
          b.map = JSON.stringify(b.map);         
          b.compoundmap = JSON.stringify(b.compoundmap);         
          return b;
        });

        this.setState({"apilist": datax, "apilistr": data});
      }
    }

  }

  constructor(props) {

    super(props);

    this.state = {

    };

    this.handleChange = this.handleChange.bind(this);
    this.lambdaMessageCallback = this.lambdaMessageCallback.bind(this);
    this.itemClicked = this.itemClicked.bind(this);

    if(this.props.loginData && this.props.loginData.userToken){
        AWS.config.credentials = au(this.props.loginData);
    } else {
      this.props.history.push("/login");  
    }

    lb.lambdaInvoke("EasyAdapter-Get" + this.props.loginData.env, {dataSet: "transformerlist", token: this.props.loginData.userToken}, this.lambdaMessageCallback());
  }

  handleChange(event) {
    this.setState({[event.target.id]: event.target.value});
  }

  itemClicked(item){
    this.setState({"jsontext": JSON.stringify(item).replaceAll("\\\"","\"")});
  }

  renderAPIListHeader(){
      return (
        <>
        <th>Id</th>
        <th>Owner</th>
        <th>IDField</th>
        <th>GmFinishSamplField</th>
        <th>GmFinishSamplTrigs</th>
        <th>Derivation</th>
        <th>windv_h_samp</th>
        <th>windv_h_ret</th>
        <th>windv_a_samp</th>
        <th>windv_a_ret</th>
        </>
      )
  }

  renderAPIListBody(){
      return this.state.apilist.sort((a,b)=>a.transformid.localeCompare(b.transformid)).map((item, key) => {
          return (
              <tr key={item.transformid}>
                 <td><CopyToClipboard text={item.transformid} onCopy={()=>this.setState({copied: true})}><button onClick={()=>{this.itemClicked(item)}}>{item.transformid}</button></CopyToClipboard></td>
                 <td>{item.ownaccid}</td>
                 <td>{item.idfieldname}</td>
                 <td>{item.gameFinishedSampleField}</td>
                 <td>{item.gameFinishedSampleTriggers}</td>
                 <td>{item.windv}</td>                 
                 <td>{item.windv_h_samp}</td>
                 <td>{item.windv_h_ret}</td>
                 <td>{item.windv_a_samp}</td>
                 <td>{item.windv_a_ret}</td>
              </tr>                        
          ) 
      }); 
  }

  renderAPIList(){
      return(
        <div>
            <table className="table">
                <thead>
                    <tr>{ this.renderAPIListHeader() }</tr>
                </thead>
                <tbody>
                    { this.renderAPIListBody() }
                </tbody>  
            </table>
          </div>
      )  
  }


  render() {

    return (

      
      <div>
        <br/>
        <br/>
        <br/>
        <br/>
        
        { this.state.jsontext && this.state.jsontext != "" && (<div style={{"display":"inline-block", "marginTop":"10px", "marginBottom":"20px", "border": "1px dashed gray", "padding":"10px", "width":"75%", "overflowX":"scroll"}}>
          <label>{this.state.jsontext}</label>
        </div>)}

        <div>
          {this.state.apilist && this.renderAPIList()}
        </div>

        <div>
          {!this.state.apilist && (
                <Spinner
                  color={"black"}
                  style={{ height: "25px", margin: "10px" }}
                  className="spinner"
                />
          )}
        </div>

      </div>
    );
  }

}