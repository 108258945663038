import React from 'react';
import ReactDOM from 'react-dom';
import * as lb from './lambda.js';
import './css/easyadapter.css';
import { Spinner } from "./spinner";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AWS from 'aws-sdk';
import { ua, au } from './ac';

export class AdapterListForm extends React.Component {

  toSmallTable(values, field){
    return (
      <table><tbody>
        {values.map(value => {
          return (<tr key={value[field]}><td style={{"padding":"1px", "margin":"1px", "textAlign":"left"}}>{value[field]}</td></tr>)
        })}
      </tbody></table>
    )
  }


  lambdaMessageCallback(message, checkUser, checkChanged) {

    return (err, data)=>{

        if (err){
          this.props.history.push("/login"); 
          return; 
        }

        var output = JSON.parse(data.Payload);
        var body = JSON.parse(output.body);
 
        let bodyx = body.map(b => { 
          b.transformerid = b.transformers.map(t => t.transformid).join(" ").trim(); 
          b.apid = b.apis.map(ap => ap.apid).join(" ").trim();
          return b; 
        });

        this.setState({"apilist": bodyx, "apilistr": body});
    }

  }

  constructor(props) {

    super(props);

    this.state = {

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.lambdaMessageCallback = this.lambdaMessageCallback.bind(this);
    this.itemClicked = this.itemClicked.bind(this);
    this.handleRebuild = this.handleRebuild.bind(this);

    if(this.props.loginData && this.props.loginData.userToken){
        console.log("this.props.loginData", this.props.loginData);
        AWS.config.credentials = au(this.props.loginData);
    } else {
      this.props.history.push("/login");  
    }

    lb.lambdaInvoke("EasyAdapter-Get" + this.props.loginData.env, {dataSet: "adapterlist", token: this.props.loginData.userToken}, this.lambdaMessageCallback());

  }

  handleRebuild(jsonspec) {

    let lambda = new AWS.Lambda();

    AWS.config.credentials.get(()=>{

      try{

        var requestData = {
          token: this.props.loginData.userToken,
          secret: this.state.secret,    
          objectArray: JSON.stringify(jsonspec)
        }

        lambda.invoke({
            FunctionName: "EasyAdapter-Put" + this.props.loginData.env,
            Payload: JSON.stringify(requestData)
        }, (err,data)=>{

          this.setState({saving: false});
          if (err){

            let reporterror;
            if (err.toString().includes("AccessDeniedException")){
              reporterror = "Access denied. Are you logged in ?";
            } else {
              reporterror = "Error reported: " + err.toString().substring(0,200);
            }
            this.setState({reqerror: reporterror});
          } else {
            console.log("Ok");
            this.setState({reqsuccess: data.Payload});
          }
          
        });

      }
      catch(e){
        console.log(e);
        this.setState({"reqerror": e.toString().substring(0,200)});
      }

    });

  }

  handleChange(event) {
    this.setState({[event.target.id]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  itemClicked(item){
    this.setState({"jsontext": JSON.stringify(this.state.apilistr.find(a=>a.adapterid==item.adapterid))});
  }

  rebuild(item){

    let rebuildSpec = [{ "adt": item.definition}];
    console.log(JSON.stringify(rebuildSpec));
    this.handleRebuild(rebuildSpec);
  }

  setupClicked(itemid){
    console.log("auth", itemid);
  }

  apiClicked(itemid){
    console.log("auth", itemid);
  }

  authClicked(itemid){
    console.log("auth", itemid);
  }

  transformClicked(itemid){
    console.log("transform", itemid)
  }

  createApi(){
    console.log("Creating api");
  }

  renderAPIListHeader(){
      return (
        <>
        <th>Id</th>
        <th>Owner</th>
        <th>Title</th>
        <th>Descr</th>
        <th>Specs</th>        
        <th>APIs</th>
        <th>Authentications</th>
        <th>Transformers</th>
        </>
      )
  }


  renderAPIListBody(){
      return this.state.apilist.sort((a,b)=>a.adapterid.localeCompare(b.adapterid)).map((item, key) => {
          return (
              <tr key={item.adapterid}>
                 <td><CopyToClipboard text={item.adapterid} onCopy={()=>this.setState({copied: true})}><button onClick={()=>{this.itemClicked(item)}}>{item.adapterid}</button></CopyToClipboard></td>
                 <td>{item.ownaccid}</td>
                 <td>{item.title}</td>                
                 <td>{item.description}</td>
                 <td className="text-xs-left" style={{"maxWidth":"250px", "overflowX":"auto"}}>{this.toSmallTable(item.specs, "specid")}</td>              
                 <td className="text-xs-left" style={{"maxWidth":"250px", "overflowX":"auto"}}>{this.toSmallTable(item.apis, "apid")}</td>
                 <td className="text-xs-left" style={{"maxWidth":"250px", "overflowX":"auto"}}>{this.toSmallTable(item.authentications, "authid")}</td>
                 <td className="text-xs-left" style={{"maxWidth":"250px", "overflowX":"auto"}}>{this.toSmallTable(item.transformers, "transformid")}</td>
              </tr>                        
          ) 
      }); 
  }

  renderAPIList(){
      return(
        <div>
            <table className="table">
                <thead>
                    <tr>{ this.renderAPIListHeader() }</tr>
                </thead>
                <tbody>
                    { this.renderAPIListBody() }
                </tbody>  
            </table>
          </div>
      )  
  }

  render() {

    return (
      
      <div>
        <br/>
        <br/>
        <br/>

        { this.state.jsontext && this.state.jsontext != "" && (<div style={{"display":"inline-block", "marginTop":"10px", "marginBottom":"20px", "border": "1px dashed gray", "padding":"10px", "width":"90%", "overflowX":"scroll"}}>
          <label>{this.state.jsontext}</label>
        </div>)}

        <div>{this.state.apilist && this.renderAPIList()}</div>
        <div>
          {!this.state.apilist && !this.state.err == true && (
                <Spinner
                  color={"black"}
                  style={{ height: "25px", margin: "10px" }}
                  className="spinner"
                />
          )}
        </div>

      </div>
    );
  }

}