import React from 'react';

import {ReactComponent as EditIcon} from './img/edit.svg';
import {ReactComponent as TrashIcon} from './img/trash-2.svg';
import {ReactComponent as SaveIcon} from './img/save.svg';
import {ReactComponent as CancelIcon} from './img/x-square.svg';
import {ReactComponent as MinimizeIcon} from './img/minimize-2.svg';

import './css/easyadapter-tiles.css';
import convertimg from './img/convert.png'; 

export default class AdapterTile extends React.Component {

  constructor(props) {
    super(props);
  
    this.state = {
      adapterid: "",
      title: "",
      description: ""
    };

    this.state.adapterid = this.props.adapter.adapterid;
    this.state.title = this.props.adapter.title;
    this.state.description = this.props.adapter.description;

    this.handleChange = this.handleChange.bind(this);

  }

  meSelected(){
      return this.props.adapterSelected && this.props.adapter.adapterid == this.props.adapterSelected.adapterid;
  }

  meEdited(){
      return this.props.modeEdit && this.props.adapterSelected && this.props.adapter.adapterid == this.props.adapterSelected.adapterid;
  }

  meUnSelectable(){
      return this.props.modeEdit && this.props.adapterSelected && this.props.adapter.adapterid != this.props.adapterSelected.adapterid;
  }

  thisTileSelect(){
      if (!this.meUnSelectable()) this.props.tileClickPFunc(this.props.adapter);
  }
 
  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  render() {

    return (

        <div onClick={()=>{ if (!this.meEdited()) this.thisTileSelect() }} className={"adapter-tile " + (this.meEdited() ? "adapter-tile-edited" : this.meSelected() ? "adapter-tile-selected" : this.meUnSelectable() ? "adapter-tile-unselectable" : "adapter-tile-unselected")} >

          { !this.meEdited() && (
              <div>
                <span className="adapter-tile-header">{this.props.adapter.adapterid}</span>
                <hr/>        
                <span className="adapter-tile-subtext no-select">{this.props.adapter.description}</span>
              </div>
          )}
          
          {  this.meEdited() && (
              <div>
                <div style={{"fontWeight":"bold", "fontSize":"9px"}}>ID (change will clone)<input type="text" id="adapterid" value={this.state.adapterid} onChange={(e)=>{this.handleChange(e)}} className="adapter-edit-box"/></div>
                <div style={{"fontWeight":"bold", "fontSize":"9px"}}>Title<input type="text" id="title" value={this.state.title} onChange={(e)=>{this.handleChange(e)}} className="adapter-edit-box"/></div>
                <div style={{"fontWeight":"bold", "fontSize":"9px"}}>Description<input type="text" id="description" value={this.state.description} onChange={(e)=>{this.handleChange(e)}} className="adapter-edit-box"/></div>
              </div>
          )}

          { this.meSelected() && !this.meEdited() && <EditIcon className="svgicon svgicon-right" onClick={()=>{this.props.tileEditClickPFunc(this.props.adapter)}} />}
          { this.meSelected() && this.meEdited()  && <SaveIcon className="svgicon svgicon-left" onClick={()=>{this.props.tileSaveClickPFunc(this.state)}} />}
          { this.meSelected() && this.meEdited()  && <CancelIcon className="svgicon svgicon-right" onClick={()=>{this.props.tileCancelEditClickPFunc(undefined)}} />}          
          { this.meSelected() && !this.meEdited() && <TrashIcon className="svgicon svgicon-left" onClick={()=>{this.props.tileDeleteClickPFunc(this.props.adapter.adapterid)}} />}

        </div> 

    )
  }

}