import React from 'react';
import ReactDOM from 'react-dom';
import * as lb from './lambda.js';
import './css/easyadapter.css';
import { Spinner } from "./spinner";

import { ua, au } from './ac';
import AWS from 'aws-sdk';

export class ForgotPassForm extends React.Component {

  lambdaMessageCallback(message, checkUser, checkChanged) {

    return function(err, data){
        if (err) alert(err); else alert("Done. Check your mail");
    }

  }

  constructor(props) {

    super(props);

    this.state = {
      email: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(event) {
    this.setState({[event.target.id]: event.target.value});
  }

  handleSubmit(event) {
    //this.props.setTitle("Resetting password...");
    AWS.config.credentials = ua();

    lb.lambdaInvoke('SubNinjas_LostPass', this.state, this.lambdaMessageCallback(' Password recovery mail sent', false).bind(this));
    event.preventDefault();
  }

  render() {

    const isEnabled = this.state.email.length > 0;

    return (
      <div>
        <br/>
        <br/>
        <br/>
      <form onSubmit={this.handleSubmit}>
      <table >
        <tbody>
        
        <tr><td className="sn-col-1">
          <label style={{marginLeft:"-2px"}}>Email</label></td><td>
          <input type="text" id="email" value={this.state.email} onChange={this.handleChange} /><br/>
        </td></tr>  

        </tbody></table>

        <br/>

        <input type="submit" value="Reset my Password" disabled={!isEnabled} />

      </form>
      
      </div>
    );
  }

}