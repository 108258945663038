import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import * as lb from './lambda.js';

import { ua, au } from './ac';
import AWS from 'aws-sdk';

export function ResetPassForm(props) {
  
  const [input, setInput] = useState({}) 

  let { search } = useLocation(); 
  const qs = queryString.parse(search);

  const handleInputChange = (e) => setInput({
    ...input,
    [e.currentTarget.name]: e.currentTarget.value
  });

  function handleSubmit(event) {
    
    //props.setTitle("Resetting password...");
    const changePassData = { email: qs.email, lost: qs.lost, password: input.password };

    AWS.config.credentials = ua();

    lb.lambdaInvoke('SubNinjas_ResetPass', changePassData, function(err, data){ 
        if (err) alert(err); else alert("Done. You can now login with your new password");
    });

    event.preventDefault();
  }

  let isEnabled = (input.password === input.password2) && (qs.email !== '' && qs.email !== null) && (qs.lost !== '' && qs.lost !== null);

  return (
    <div>
      <form onSubmit={handleSubmit}>
      <br/>
      <br/>
      <br/>
      <table><tbody>
        
        <tr><td className="sn-col-1">
          <label>New Password</label></td><td>
          <input type="password" name="password" onChange={handleInputChange} /><br/>
        </td></tr>  

        <tr><td>
          <label>Verify New Password</label></td><td>
          <input type="password" name="password2" onChange={handleInputChange} /><br/>
        </td></tr>  

        </tbody></table>

        <br/>

        <input type="submit" value="Reset Password" disabled={!isEnabled} />

      </form>
    </div>
  );

}