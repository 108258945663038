import React from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'react-bootstrap';
import './css/easyadapter.css';
import { Spinner } from "./spinner";

import template_adapter from './json/templates/template_adapter.json';
import template_api from './json/templates/template_api.json';
import template_authenticator from './json/templates/template_authenticator.json';
import template_authenticator_linked from './json/templates/template_authenticator_linked.json';
import template_delete from './json/templates/template_delete.json';
import template_policy from './json/templates/template_policy.json';
import template_spec from './json/templates/template_spec.json';
import template_transfer_ownership from './json/templates/template_transfer_ownership.json';
import template_transformer from './json/templates/template_transformer.json';
import template_trigger from './json/templates/template_trigger.json';

import { ua, au } from './ac';
import AWS from 'aws-sdk';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

const newid = (x) => [...Array(x)].map(i=>(~~(Math.random()*36)).toString(36)).join('');

export class RecordMakerForm extends React.Component {

  isObject(obj){ 
    return typeof(obj) == "object"; 
  }

  lambdaCallback(err, data){
        
      if (err){
        this.setState({notLoggedIn: true});
        this.setState({status: err});

      } else {
        this.setState({"apilist": data});
        this.setState({notLoggedIn: false});
        this.setState({status: "ok"});

      }

  }

  constructor(props) {

    super(props);

    this.state = {
        auid: "",
        uuid: "Unauthenticated",
        identityId: "Unauthenticated",
        description: "a",
        pathauth: "b",
        paramsauth: "c",
        headersauth: "d",
        jsonspec: "",
        saveto: "none selected",
        description: "Generic description",
        secret:""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.itemClicked = this.itemClicked.bind(this);
    this.handleSubmitNewObject = this.handleSubmitNewObject.bind(this);

    if(this.props.loginData && this.props.loginData.userToken){
        AWS.config.credentials = au(this.props.loginData);
    } else {
      this.props.history.push("/login");  
    }
   
  }


  handleSubmitNewObject(event) {

    this.setState({reqerror: false});
    this.setState({reqsuccess: false});
    this.setState({saving: true});

    event.preventDefault();

    let lambda = new AWS.Lambda();

    AWS.config.credentials.get(()=>{


      try{

        var objectArray = JSON.stringify(JSON.parse(this.state.jsonspec));

        var requestData = {
          token: this.props.loginData.userToken,
          secret: this.state.secret,    
          objectArray: objectArray,
          saveEncrypted: this.state.doencrypt     
        }

        lambda.invoke({
            FunctionName: "EasyAdapter-Put" + this.props.loginData.env,
            Payload: JSON.stringify(requestData)
        }, (err,data)=>{

          this.setState({saving: false});
          if (err){

            let reporterror;
            if (err.toString().includes("AccessDeniedException")){
              reporterror = "Access denied. Are you logged in ?";
            } else {
              console.log("EE",err.toString());
              reporterror = "Error reported: " + err.toString().substring(0,200);
            }
            this.setState({reqerror: reporterror});
          } else {
            this.setState({reqsuccess: data.Payload});
          }
          
        });

      }
      catch(e){
        this.setState({"reqerror": e.toString().substring(0,200)});
      }

    });

  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleCheckChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  itemClicked(itemid){
    this.setState({"selectedId": itemid});
  }

 
  loadJson(tabletag, which){
    this.setState({jsonspec: JSON.stringify(which, undefined, 4), saveTo: tabletag});
  }
  

  pretty(){
      this.setState({"jsonspec": JSON.stringify(JSON.parse(this.state.jsonspec),undefined,4)});
  }


  renderInputForm(){

    return (
    
      <div style={{"width": "75%", "display":"inline-block"}} className="form-group">

       <div style={{"display":"block", "marginTop":"10px", "marginBottom":"10px", "width":"100%"}}>
        <button className="template-button" onClick={()=>{this.loadJson('adt',template_adapter)}}>Adapter Template</button>
        <button className="template-button" onClick={()=>{this.loadJson('api',template_api)}}>API Template</button>
        <button className="template-button" onClick={()=>{this.loadJson('aut',template_authenticator)}}>Authenticator Template</button>
        <button className="template-button" onClick={()=>{this.loadJson('aut',template_authenticator_linked)}}>Authenticator Template (linked)</button>
        <button className="template-button" onClick={()=>{this.loadJson('del',template_delete)}}>Delete Object Template</button>
        <button className="template-button" onClick={()=>{this.loadJson('pol',template_policy)}}>Policy Template</button>  
        <button className="template-button" onClick={()=>{this.loadJson('set',template_spec)}}>Spec Template</button>        
        <button className="template-button" onClick={()=>{this.loadJson('own',template_transfer_ownership)}}>Clone Template</button>
        <button className="template-button" onClick={()=>{this.loadJson('tra',template_transformer)}}>Transformer Template</button>
        <button className="template-button" onClick={()=>{this.loadJson('trg',template_trigger)}}>Trigger Template</button>
        <button className="template-button" onClick={()=>{this.pretty()}}>Pretty JSON</button>
      </div>   


      <form onSubmit={this.handleSubmitNewObject}>

      <table>
      <tbody>                 

      <tr>
      <td>
       
      </td>
      </tr>
      <tr>
      <td><textarea id="jsonspec" className="form-control" type="textarea" cols="100" rows="10" value={this.state.jsonspec} onChange={this.handleChange} style={{"fontSize":"12px", "width":"750px"}}/></td>
      </tr>
      
      <tr>
      <td><br/>
      <label>
        <input type="checkbox" id="doencrypt" name="doencrypt" value={this.state.doencrypt} onChange={this.handleCheckChange} style={{"marginLeft":"6px"}}/>
        &nbsp;&nbsp;Encrypt AUT
      </label></td>
      </tr>

      <tr><td>

        { this.state.doencrypt && (
          <>
          <div style={{"marginLeft":"6px"}}>
          <label>
            <input type="password" className="form-control" id= "secret" value={this.state.secret} onChange={this.handleChange} /><br/>
            Paste encryption secret here and keep it safe.
          </label>
          </div>
          <br/>
          </>
        )}
      </td></tr>

      <tr>
      <td><input type="submit" value="Save"/></td>
      </tr>    
      </tbody>                 

      </table>
            
      </form>
      </div>
    )

  }

  render() {

    return (


      <div>
        <br/><br/><br/>

        <div style={{"display": "inline-block"}}>
          {this.renderInputForm()}
        </div>

        <br/>
        <div style={{"display": "inline-block"}}>

          <div>
            {this.state.saving && !this.state.reqerror && (
                  <Spinner
                    color={"black"}
                    style={{ height: "25px", margin: "10px" }}
                    className="spinner"
                  />
            )}
          </div>

          { this.state.reqerror && (
              <div>That did not seem to work terribly well. Error reported from server. Check json structure</div>

          )}

          { this.state.reqsuccess && (
              <div>Command submitted. Reply: { JSON.stringify(this.state.reqsuccess) }</div>

          )}

        </div>

      </div>


    );
  }

}