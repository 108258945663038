import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import * as lb from './lambda.js';

import { ua, au } from './ac';
import AWS from 'aws-sdk';

export function VerifyUserForm(props) {
  
  let { search } = useLocation();  
  const qs = queryString.parse(search);
  const verifyUserData = { email: qs.email, verify: qs.verify };

  AWS.config.credentials = ua();

  lb.lambdaInvoke('SubNinjas_VerifyUser', verifyUserData, function(err, data){ 

    if (err || !data) return;
    
    var output = JSON.parse(data.Payload); 
    if (output.verified){
      alert("OK. You can now login");
      props.history.push("/login");
    }
  });

  return (<div>Please wait...</div>);

}