import React from 'react';
import ReactDOM from 'react-dom';
import * as lb from './lambda.js';

import { Spinner } from "./spinner";
import Snuggle from 'react-snuggle'

import AWS from 'aws-sdk';
import { ua, au } from './ac';

import './css/easyadapter-tiles.css';
import configKeys from './json/module_config_keys.json';

import {ReactComponent as TrashIcon} from './img/trash-2.svg';
import {ReactComponent as MinimizeIcon} from './img/minimize-2.svg';
import {ReactComponent as EditIcon} from './img/edit.svg';

import AdapterTile from './tile.adapter';

import AddObjectSelector from './common/element.add-object-selector'

/* Adapter Modules */

export class HeaderPlusTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(p,s){
    if (p.reset != this.props.reset && this.props.selectOpen) this.props.plusClick();
  }

  render() {
    return (
        <table className="header-plus-table">
          <tbody>
            <tr>
              <td width="5%" style={{"padding": "0px"}}>
                <br/>
                <h2         
                    onMouseOut={()=> { this.setState({"over":undefined })} } 
                    onMouseOver={()=>{ this.setState({"over":true})} } 
                    style={{"color": this.state.over ? this.props.color :'#aaaaaa', "marginLeft":"7px", "cursor": "pointer"}}
                    onClick={()=>{this.props.plusClick()}} valign="middle" className={"no-select " + (!this.props.grayText ? "do-gray" : "")}>{this.props.title}
                </h2>
              </td>
              <td style={{"padding": "0px"}}>
             
                  {this.props.busy && (
                       <Spinner
                        color="#2a4a57"
                        style={{ height: "20px", marginLeft: "-5px", marginTop: "30px" }}
                        className="spinner"
                      />)}

                  {this.props.editMode && (                  

                      !this.props.busy && (

                      <button onClick={()=>{this.props.plusClick()}} style={{"border":"none", "backgroundColor":"transparent", "marginTop":"3px", "float":"left", "width": "40px", "height":"40px"}}>   
                          <span 
                            className="plus-span" 
                            onMouseOut={()=> { this.setState({"over":undefined })} } 
                            onMouseOver={()=>{ this.setState({"over":true})} } 
                            style={{"color": this.state.over ? this.props.color :'#aaaaaa'}}
                          >

                            {this.props.selectOpen ? <MinimizeIcon className="minim-icon"/> : "+" }

                          </span>
                      </button> 
                      )

                  )}

              </td>
            </tr>
          </tbody> 
        </table>
    )
  }

}

export class SubTile extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className={"small-tile " + this.props.tileStyle}>
          <span className="adapter-tile-header no-select">{this.props.okey}</span>
          
          {this.props.editMode && 
            <><TrashIcon 
                className={"svgicon svgicon-left"}
                onClick={()=>{this.props.deleteSubtile(this.props.dataKey, this.props.okey)}} 
                style={{"margin":"6px","marginBottom":"7px", "stroke": this.props.tileStyleIcon}}
            />
            <EditIcon 
                className={"svgicon svgicon-right"} 
                onClick={()=>{this.props.editSubtile(this.props.dataKey, this.props.okey)}} 
                style={{"margin":"6px","marginBottom":"7px", "stroke": this.props.tileStyleIcon}}
            /></>}
        
        </div> 
    )
  }

}

export class ModuleComponent extends React.Component {

  constructor(props) {
    super(props);    
    this.state={};
  }

  loadSelectItems(){

    console.log("Loading select items");

    this.setState({"pending":true});

    let queryData = { 
      dataSet: configKeys[this.props.configKey].dataSet, 
      token: this.props.loginData.userToken 
    };

    try{
      lb.lambdaInvoke("EasyAdapter-Get" + this.props.loginData.env, queryData , (err, data) => {
        
        let pl = JSON.parse(data.Payload);
        let pb = JSON.parse(pl.body);
        let pklist = pb.map(p=>p[configKeys[this.props.configKey].pk]);

        this.setState({ "itemList": pklist });
        this.setState({ "pending": false });

      });
    } catch(e){
      this.props.history.push("/login");
    }

  }

  plusClick(){
      if (!this.props.modeEdit) return;

      let selectOpen = !this.state.selectOpen; 
      this.setState({"selectOpen": selectOpen});

      if (selectOpen) {
        this.loadSelectItems();
      }
  }

  render() {

    let activeAdapter = this.props.adapterSelected;
    if (!activeAdapter) return null;

    let activeDefinition = activeAdapter.definition;
    if (!activeDefinition) return null;

    let moduleList = activeDefinition[configKeys[this.props.configKey].dataKey];

    return (

      <div>
          <HeaderPlusTable 
              editMode={this.props.modeEdit} 
              selectOpen={this.state.selectOpen}   
              title={configKeys[this.props.configKey].title} 
              color={configKeys[this.props.configKey].color} 
              plusClick={this.plusClick.bind(this)} 
              reset={this.props.reset}
              grayText={moduleList && moduleList.length > 0}
          /> 

      
          {this.props.modeEdit && this.state.selectOpen && 
              <AddObjectSelector 
                  pending={this.state.pending} 
                  itemList={this.state.itemList} 
                  colorcss={configKeys[this.props.configKey].colorcss}
                  color={configKeys[this.props.configKey].color}
                  addObjectClick={this.props.addObjectClick}
                  selName={configKeys[this.props.configKey].dataKey}
              /> 
          }

          { moduleList && moduleList.map(o=>
              <SubTile editMode={this.props.modeEdit} editSubtile={this.props.editSubtile} deleteSubtile={this.props.deleteSubtile} tileStyleIcon={configKeys[this.props.configKey].colorIcon} tileStyle={configKeys[this.props.configKey].colorcsss} okey={o} key={o} dataKey={configKeys[this.props.configKey].dataKey}/>   
            ) 
          }
          
      </div>
    )

  }  

}

/* Adapter Modules */

export class AdaptersTilesForm extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      selectedAdapter: undefined,
      busy: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.adapterListCallback = this.adapterListCallback.bind(this);
    this.adapterSelect = this.adapterSelect.bind(this);
    this.adapterEdit = this.adapterEdit.bind(this);
    this.adapterCancelEdit = this.adapterCancelEdit.bind(this);
    this.adapterDelete = this.adapterDelete.bind(this);
    this.adapterSave = this.adapterSave.bind(this);
    this.tileCss = this.tileCss.bind(this);
    this.addObject = this.addObject.bind(this);
    this.deleteSubtile = this.deleteSubtile.bind(this);
    this.editSubtile = this.editSubtile.bind(this);

    if(this.props.loginData && this.props.loginData.userToken){
        AWS.config.credentials = au(this.props.loginData);
    } else {
      this.props.history.push("/login");  
    }

    this.refreshAdapterList();

  }

  adapterListCallback(message, checkUser, checkChanged) {

    return (err, data)=>{

        if (err){
          this.props.history.push("/login"); 
          return; 
        }

        var output = JSON.parse(data.Payload);
        var body = JSON.parse(output.body);

        this.setState({"adapterList": body});
        this.setState({"busy": false});
    }

  }

  refreshAdapterList(){

      lb.lambdaInvoke("EasyAdapter-Get" + this.props.loginData.env, {dataSet: "adapterlist", token: this.props.loginData.userToken}, this.adapterListCallback());

  }  

  submitCommandObject(jsonspec) {

    this.setState({"busy": true});

    let lambda = new AWS.Lambda();

    AWS.config.credentials.get(()=>{

      try{

        var requestData = {
          token: this.props.loginData.userToken,
          secret: this.state.secret,    
          objectArray: JSON.stringify(jsonspec)
        }

        lambda.invoke({
            FunctionName: "EasyAdapter-Put" + this.props.loginData.env,
            Payload: JSON.stringify(requestData)
        }, (err,data)=>{

          this.setState({saving: false});
          if (err){

            let reporterror;
            if (err.toString().includes("AccessDeniedException")){
              reporterror = "Access denied. Are you logged in ?";
            } else {
              reporterror = "Error reported: " + err.toString().substring(0,200);
            }
            this.setState({reqerror: reporterror});
          } else {
              this.setState({"modeEdit": false});
              this.setState({"selectedAdapter": false});
              this.setState({"reset": Math.floor(Math.random() * 100000) });
              this.setState({"busy": true});
              this.refreshAdapterList();
          }   
          
        });

      }
      catch(e){
        console.log(e);
        this.setState({"reqerror": e.toString().substring(0,200)});
      }

    });

  }

  tileCss(aa){

    return this.state.tileClicked && this.state.tileClicked.adapterid == aa ? "adapter-tile-selected" : "adapter-tile-unselected";

  }
  
  handleChange(event) {

    this.setState({[event.target.id]: event.target.value});

  }

  adapterSelect(adapter){

    this.setState({"adapterSelected": adapter});

  }
  
  adapterEdit(adapter){
    this.setState({"modeEdit": true});
    this.setState({"reset": Math.floor(Math.random() * 100000) });
  }

  adapterCancelEdit(adapter){
    this.setState({"modeEdit": false});
    this.setState({"reset": Math.floor(Math.random() * 100000) });
  }

  adapterDelete(adapterid){

    let adtDeleteTemplate = [{
      "del": {
       "table": "adt",
       "key": adapterid
      }
    }];

    setTimeout(()=>{
      this.setState({
        "modeEdit": false,
        "adapterSelected": {},
        "reset": Math.floor(Math.random() * 100000)
      }, ()=>{
         this.submitCommandObject(adtDeleteTemplate);
      })
    },1);

  }

  addObject(obj){

      let keyType = Object.keys(obj)[0];
      let keyValue = obj[keyType];
      let adapterClone = JSON.parse(JSON.stringify(this.state.adapterSelected));

      if (adapterClone.definition[keyType]){
        adapterClone.definition[keyType].push(keyValue);
      } else {
        adapterClone.definition[keyType] = [keyValue];
      }

      this.setState({"adapterSelected": adapterClone})
    
  }

  deleteSubtile(keyType, keyValue){

    let adapterClone = JSON.parse(JSON.stringify(this.state.adapterSelected));
    adapterClone.definition[keyType] = adapterClone.definition[keyType].filter(d=>d!=keyValue);

    this.setState({"adapterSelected": adapterClone})

  }

  editSubtile(keyType, keyValue){

  }

  adapterSave(changeObj){

      let spec = this.state.adapterSelected.definition;

      spec.adapterid = changeObj.adapterid;
      spec.title = changeObj.title;
      spec.description = changeObj.description;

      let rebuild = [{ "adt": spec}];

      this.submitCommandObject(rebuild);

  }

  addAdapter(){

    let adtTemplate = [{
        "adt": {
         "title": "title", 
         "description": "description"
        }
    }];

    this.submitCommandObject(adtTemplate);

  }

  render() {
    return (
      <div>
        <br/>


        <table className="table table-tiles">
          <tbody>
          <tr>

            {/* Adapter List */} 
            <td valign="top" width="85%">

                <HeaderPlusTable 
                    title="Adapters" 
                    editMode={true} 
                    selectOpen={false} 
                    color="#2a4a57"
                    plusClick={this.addAdapter.bind(this)} 
                    busy={this.state.busy || !this.state.adapterList}
                    />

                <div className="adapter-list">
                    {this.state.adapterList && this.state.adapterList.sort((a,b)=>a.adapterid.localeCompare(b.adapterid)).map(a=>
                      <div key={a.adapterid}>
                         <AdapterTile 
                            adapter={a}
                            adapterSelected={this.state.adapterSelected}                         
                            modeEdit={this.state.modeEdit}    
                            class={"adapter-tile " + this.tileCss(a.adapterid)} 
                            tileClickPFunc={this.adapterSelect} 
                            tileEditClickPFunc={this.adapterEdit} 
                            tileCancelEditClickPFunc={this.adapterCancelEdit} 
                            tileDeleteClickPFunc={this.adapterDelete} 
                            tileSaveClickPFunc={this.adapterSave} 
                        />
                      </div> 
                    )}
                </div>
            </td>

            {/* Modules list */} 
            <td valign="top" width="15%" style={{"minWidth":"300px"}}>
                <div style={{"marginTop":"-0px"}}>
                    <ModuleComponent deleteSubtile={this.deleteSubtile} configKey="API" modeEdit={this.state.modeEdit} addObjectClick={this.addObject} reset={this.state.reset} adapterSelected={this.state.adapterSelected}  loginData={this.props.loginData} />
                    <ModuleComponent deleteSubtile={this.deleteSubtile} configKey="TRA" modeEdit={this.state.modeEdit} addObjectClick={this.addObject} reset={this.state.reset} adapterSelected={this.state.adapterSelected}  loginData={this.props.loginData} />
                    <ModuleComponent deleteSubtile={this.deleteSubtile} configKey="AUT" modeEdit={this.state.modeEdit} addObjectClick={this.addObject} reset={this.state.reset} adapterSelected={this.state.adapterSelected}  loginData={this.props.loginData} />
                    <ModuleComponent deleteSubtile={this.deleteSubtile} configKey="SPC" modeEdit={this.state.modeEdit} addObjectClick={this.addObject} reset={this.state.reset} adapterSelected={this.state.adapterSelected}  loginData={this.props.loginData} />                          
                </div>
            </td>

          </tr>
          </tbody>
        </table>
      </div>
    );
  
  }

}                

