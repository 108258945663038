import AWS from 'aws-sdk';

export function lambdaInvoke(functionName, payloadObj, callback){
	
    let lambda = new AWS.Lambda();

	lambda.invoke({
	    FunctionName: functionName,
	    Payload: JSON.stringify(payloadObj)
	}, callback);
 
}