import React from 'react';
import '../css/easyadapter.css';
import { Spinner } from "../spinner";

export default class AddObjectSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }
 //sort((a,b)=>a[this.props.sortKey].localeCompare(b.[this.props.sortKey])).
  handleSelectChange(e) {
    let {name, value} = e.target;
    if (this.props.valueReporter) this.props.valueReporter(value);
    this.setState({[name]: value});
  }

  render() {

    return (
      <div>
          <div style={{"display":"inline-block"}}>

              { this.props.pending &&
                 <Spinner
                  color={this.props.color}
                  style={{ height: "25px","marginBottom": "6px" }}
                  className="spinner"
                />
              }

              { !this.props.pending &&
                <select className={"select color-select " + (this.props.colorcss)} onChange={this.handleSelectChange.bind(this)} name={this.props.selName}>
                  { this.state[this.props.selName] === undefined && <option value="">Select an item</option> }
                  { this.props.addLiteral && <option value="$literal">$literal</option> }
                  { this.props.addLiteral && <option value="$compare">$compare</option> }
                  { this.props.addLiteral && <option value="$optm_act">$optm_act</option> }

                  { this.props.itemList && this.props.itemList.sort((a,b)=>a.localeCompare(b)).map(itl=>{
                    return (<option key={itl} value={itl}>{itl}</option>)
                  })}
                </select>
              }

          </div>
          &nbsp;
          
          { this.state[this.props.selName] !== undefined && !this.props.hideAdd && (
          <div style={{"display":"inline-block"}}>
              <button onClick={()=>{this.props.addObjectClick(this.state)}} className={"button color-select-button " + this.props.colorcss + "-button"}>Add</button>
          </div>)}

      </div>
    )
  }

}