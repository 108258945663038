import React from 'react';
import ReactDOM from 'react-dom';
import { Redirect, useHistory } from "react-router-dom";
import './css/easyadapter.css';
import { Spinner } from "./spinner";

import AWS from 'aws-sdk';
import { ua, au } from './ac';
AWS.config.region = 'eu-west-1';

export class LoginForm extends React.Component {


  lambdaMessageCallback(message, checkUser) {

    return function(err, data){

        if (err) { alert("Login error"); return; }

        var output = JSON.parse(data.Payload);

        this.props.setLoginData({loginData: output});

        if (output.login === false){
            this.setState({"userloggedin": false});
            this.setState({"loggingIn": false});
        }
        else {
            this.setState({"userloggedin": true});
            this.setState({"loggingIn": false});
            this.props.setMenu("TIL");
            this.props.history.push("/mp");
        }
    }

  }

  constructor(props) {

    super(props);

    this.state = {
      email: '',
      password: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(event) {

    this.setState({ [event.target.id]: event.target.value });
  
  }

  handleSubmit(event) {

    this.setState({"loggingIn": true});

    AWS.config.credentials = ua();

    AWS.config.credentials.get(()=>{
      let lambda = new AWS.Lambda();

      lambda.invoke({
          FunctionName: "SubNinjas_Login",
          Payload: JSON.stringify(this.state)
      }, this.lambdaMessageCallback('User logged in').bind(this));    

    });

    event.preventDefault();
  
  }

  render() {

    return (

      <form onSubmit={ this.handleSubmit }>
        <br/>
        <br/>
        <br/>
        <table style={{"marginLeft":"4px"}}>
        <tbody>

        <tr><td className="sn-col-1">
          <label>Login</label></td><td>
          <input type="text" id="email" value={this.state.email} onChange={this.handleChange} /><br/>
        </td></tr> 

        <tr><td>
        <label>Pass</label></td><td> 
          <input type="password" id= "password" value={this.state.password} onChange={this.handleChange} /><br/>
        </td></tr> 
        
        </tbody></table>
        
        <br/>

        <div>       

        {!this.state.loggingIn && !this.state.userloggedin && (
          <input type="submit" value="Login"  />
        )}

          {this.state.loggingIn && !this.state.userloggedin && (
                <Spinner
                  color={"black"}
                  style={{ height: "25px", margin: "10px", marginLeft:"-10px", "marginTop":"0px" }}
                  className="spinner"
                />
          )}
        </div>

      </form>          

    );

  }

}