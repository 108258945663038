import React from 'react';
import ReactDOM from 'react-dom';
import { Redirect, useHistory } from "react-router-dom";
import './css/easyadapter.css';
import { Spinner } from "./spinner";

import AWS from 'aws-sdk';
import { ua, au } from './ac';
import './css/easyadapter.css';

AWS.config.region = 'eu-west-1';

export class MyPageForm extends React.Component {


  constructor(props) {

    super(props);

    if(props.loginData && props.loginData.userToken){
        //AWS.config.credentials = au(props.loginData);
    } else {
      this.props.history.push("/login");  
    }


  }

  handleChange(event) {

    this.setState({ [event.target.id]: event.target.value });
  
  }


  render() {

    return (

      <>
        <br/>
        <br/>
        <br/>
        <br/>
        <h3>My Page</h3>
      </>          

    );

  }

}