import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import * as lb from './lambda.js';
import './css/easyadapter.css';
import { Spinner } from "./spinner";

import { ua, au } from './ac';
import AWS from 'aws-sdk';

export class SignupForm extends React.Component {


  lambdaMessageCallback(message, checkUser, checkChanged) {

    return function(err, data){

        if (!data || !data.Payload || err){
          this.setState({"signupError": true});
          console.log(err);
          console.log(data);
        }

        var output = JSON.parse(data.Payload);

        if (output.login === false || output.verified === false || output.created === false){
          this.setState({"servermessage": "Operation Failed"});
          this.setState({"signingUp": false});
          this.setState({"userSignedUp": false});          
        }
        else{
          let successmessage = message + " IMPORTANT !!! This is your API key, keep it safe, you will not be able to retrieve it";
          this.setState({"servermessage": successmessage, "apisecret": output.apisecret, "apiaccountid": output.accountid});
          this.setState({"signingUp": false, "userSignedUp": true});    
        }
    }

  }

  constructor(props) {

    super(props);

    this.state = {
      email: '',
      username: '',
      password: '',
      password2: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    AWS.config.credentials = ua();

  }

  handleChange(event) {
    this.setState({[event.target.id]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();   
    this.setState({"signingUp": true});
    AWS.config.credentials = ua();
    AWS.config.credentials.get(()=>{
      lb.lambdaInvoke("SubNinjas_CreateUser", this.state, this.lambdaMessageCallback('User created, check your mail.', false).bind(this));
    });
  }

  render() {

    const isEnabled = this.state.email.length > 0 && this.state.password.length > 0 && (this.state.password === this.state.password2);

    return (
      <>
      <form onSubmit={this.handleSubmit}>
        <br/>
        <br/>
        <br/>

      <table><tbody>
        
        <tr><td className="sn-col-1">
          <label>Email</label></td><td>
          <input type="text" id="email" value={this.state.email} onChange={this.handleChange} /><br/>
        </td></tr>  

        <tr><td>
          <label>UserName</label></td><td>
          <input type="text" id= "username" value={this.state.username} onChange={this.handleChange} /><br/>    
        </td></tr>   

        <tr><td>
          <label>Password</label></td><td>
          <input type="password" id="password" value={this.state.password} onChange={this.handleChange} /><br/>
        </td></tr> 

        <tr><td>        
          <label>Password</label></td><td>
          <input type="password" id= "password2" value={this.state.password2} onChange={this.handleChange} /><br/> 
        </td></tr> 

        </tbody></table>
<br/>
        Please select a password at least 8 characters with a number
<br/>
        <br/>
        <input type="submit" value="Sign up" disabled={!isEnabled} />

      </form>

      <div>
        {this.state.signingUp && !this.state.userSignedUp && (
              <Spinner
                color={"black"}
                style={{ height: "25px", margin: "10px" }}
                className="spinner"
              /> 
        )}
      </div>

      { this.state.servermessage &&
      <>
      <div style={{"marginTop":"50px"}}><h5>Secret:     {this.state.apisecret}</h5></div>
      <div style={{"marginTop":"50px"}}><h5>AccountId:     {this.state.apiaccountid}</h5></div>
      <div style={{"marginTop":"50px"}}><h5>Check your mail for verification link</h5></div>
      </>
      }

      { this.state.signuperror &&
      <>
      <div style={{"marginTop":"50px"}}>Signup error. Please try again</div>
      </>
      }

      </>
    );
  }

}