import x from 'aws-sdk';
var _$_84da=["\x49\x64\x65\x6E\x74\x69\x74\x79\x50\x6F\x6F\x6C\x49\x64","\x65\x75\x2D\x77\x65\x73\x74\x2D\x31\x3A\x39\x62\x65\x33\x32\x30\x37\x64\x2D\x32\x30\x39\x64\x2D\x34\x38\x35\x35\x2D\x62\x65\x65\x62\x2D\x65\x36\x38\x34\x63\x32\x63\x38\x35\x63\x63\x36","\x63\x6F\x67\x6E\x69\x74\x6F\x2D\x69\x64\x65\x6E\x74\x69\x74\x79\x2E\x61\x6D\x61\x7A\x6F\x6E\x61\x77\x73\x2E\x63\x6F\x6D","\x49\x64\x65\x6E\x74\x69\x74\x79\x49\x64","\x4C\x6F\x67\x69\x6E\x73","\x69\x64\x65\x6E\x74\x69\x74\x79\x49\x64","\x75\x73\x65\x72\x54\x6F\x6B\x65\x6E","\x43\x6F\x67\x6E\x69\x74\x6F\x49\x64\x65\x6E\x74\x69\x74\x79\x43\x72\x65\x64\x65\x6E\x74\x69\x61\x6C\x73"];
const f=_$_84da[0];
const p=_$_84da[1];
const c=_$_84da[2];
const t=_$_84da[3];
const q=_$_84da[4];
const y=_$_84da[5];
const k=_$_84da[6];
const u=_$_84da[7];
export function ua(){return  new x[u]({[f]:p})}
export function au(_0x21DE4){return  new x[u]({[f]:p,[t]:_0x21DE4[y],[q]:{[c]:_0x21DE4[k]}})}