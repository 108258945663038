
// ========== Imports =================

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Switch, Route, Link, useLocation } from 'react-router-dom';
import { Container} from 'react-bootstrap';

import * as frmHome from './frm.home';
import * as frmVerifyUser from './frm.verifyUser';
import * as frmResetPass from './frm.resetPass';
import * as LoginFormPage from './frm.login';
import * as SignupPage from './frm.signup';
import * as ChangePassPage from './frm.changePass';
import * as ForgotPassPage from './frm.forgotPass';
import * as MyPagePage from './frm.myPage';


import * as APIListPage from './frm.apis';
import * as AdapterListPage from './frm.adapters';
import * as AuthenticatorsListPage from './frm.authenticators';
import * as TransformersListPage from './frm.transformers';
import * as RecordMakerPage from './frm.recordMaker';
import * as SpecsListPage from './frm.specs';
import * as TriggerListPage from './frm.triggers';
import * as CLJobsPage from './frm.cljobs';

import * as AdaptersTilesPage from './frm.adapters-tiles';
import * as SetupBuilderPage from './frm.setup-builder';

import banner from './img/easyadapterfull.png'; 
import './css/index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { createBrowserHistory } from "history";

const history = createBrowserHistory()

// ============== Router ===================

class SubNinjasRouter extends React.Component {

  constructor(props){
    
    super(props);
    
    this.state = { 
      loginData: {
        login: false,
        userUid: "",
        userName: "",
        userToken: "Not logged in",
        identityId: ""
      }
    }

  }


  setLoginData(msg){

   this.setState({
      loginData: {
        userToken: msg.loginData.token,
        userUid: msg.loginData.uid,
        userName: msg.loginData.username,
        identityId: msg.loginData.identityId,
        login: msg.loginData.login,
        env: this.props.env
      }
    });
  }

  logout(){
    this.setState({"loginData": undefined, "CL":"SNU"});
  }

  setMenu(code){
    this.setState({"CL":code})
  }

  render(){
    return (
      <div>
      <Router>
          <nav style={{"float": "left", "backgroundColor": "#dddddd"}}>
            <ul style={{"float": "left", "backgroundColor": "#dddddd"}}>
             <center>
              { (!this.state.loginData || !this.state.loginData.login || this.state.loginData.login == false) && 
                <>
                  <li><Link className={this.state.CL == "SNU"?"nav-selected":""} onClick={()=>this.setState({"CL":"SNU"})} to="/login">Login</Link></li>
                  <li><Link className={this.state.CL == "NAC"?"nav-selected":""} onClick={()=>this.setState({"CL":"NAC"})} to="/signup">New account</Link></li>
                  
                  <li><Link className={this.state.CL == "FGP"?"nav-selected":""} onClick={()=>this.setState({"CL":"FGP"})} to="/forgotpass">Forgot Pass</Link></li>
                </>
              }

              {/* <li><Link className={this.state.CL == "CLJ"?"nav-selected":""} onClick={()=>this.setState({"CL":"CLJ"})} to="/cljobs">CL Job Tool</Link></li> */}

              { (this.state.loginData && this.state.loginData.login && this.state.loginData.login != false) && 
                <>  
                {/*        
                  <li><Link className={this.state.CL == "API"?"nav-selected":""} onClick={()=>this.setState({"CL":"API"})} to="/apis">APIs</Link></li>
                  <li><Link className={this.state.CL == "TRA"?"nav-selected":""} onClick={()=>this.setState({"CL":"TRA"})} to="/transformers">Transformers</Link></li>
                  <li><Link className={this.state.CL == "AUT"?"nav-selected":""} onClick={()=>this.setState({"CL":"AUT"})} to="/authenticators">Authenticators</Link></li>
                  <li><Link className={this.state.CL == "SET"?"nav-selected":""} onClick={()=>this.setState({"CL":"SET"})} to="/specs">Specs</Link></li>              
                  <li><Link className={this.state.CL == "TRG"?"nav-selected":""} onClick={()=>this.setState({"CL":"TRG"})} to="/triggers">Triggers</Link></li> 
                  <li><Link className={this.state.CL == "ADT"?"nav-selected":""} onClick={()=>this.setState({"CL":"ADT"})} to="/adapters">Adapters</Link></li>                     
                  <li><Link className={this.state.CL == "TIL"?"nav-selected":""} onClick={()=>this.setState({"CL":"TIL"})} to="/adapterstiles">Adapter Builder</Link></li>                     
                  <li><Link className={this.state.CL == "SBL"?"nav-selected":""} onClick={()=>this.setState({"CL":"SBL"})} to="/setupbuilder">Specification Builder</Link></li>                     
                  <li><Link className={this.state.CL == "MKR"?"nav-selected":""} onClick={()=>this.setState({"CL":"MKR"})} to="/rmaker">Command Input</Link></li>
                */}
                  <li><Link className={this.state.CL == "MP"?"nav-selected":""} onClick={()=>this.setState({"CL":"MP"})} to="/mp">My Page</Link></li>
                  <li><Link className={this.state.CL == "CHP"?"nav-selected":""} onClick={()=>this.setState({"CL":"CHP"})} to="/changepass">Change Pass</Link></li>

                  { this.state.loginData && <li><Link className={this.state.CL == "xxx"?"nav-selected":""} onClick={()=>this.logout()} to="/login">Logout ({this.state.loginData.userUid})</Link></li>}
                </>
              }

              </center>
            </ul>
            
          </nav>

          <div style={{"marginLeft":"10px"}}>
            <Switch>
          
              <Route path='/mp'             render = { props => <MyPagePage.MyPageForm loginData={this.state.loginData} {...props} /> } />    
              <Route path='/login'          render = { props => <LoginFormPage.LoginForm setMenu={this.setMenu.bind(this)} setLoginData={this.setLoginData.bind(this)} {...props} /> } />  
              <Route path='/signup'         render = { props => <SignupPage.SignupForm {...props} /> } />
              <Route path='/changepass'     render = { props => <ChangePassPage.ChangePassForm loginData={this.state.loginData} {...props } /> } />
              <Route path='/forgotpass'     render = { props => <ForgotPassPage.ForgotPassForm {...props} /> } />              
              <Route path='/reset'          render = { props => <frmResetPass.ResetPassForm {...props} /> } />           
              <Route path='/verify'         render = { props => <frmVerifyUser.VerifyUserForm  {...props} /> } />    

         {/*
              <Route path='/apis'           render = { props => <APIListPage.APIListForm loginData={this.state.loginData} {...props} /> } />    
              <Route path='/authenticators' render = { props => <AuthenticatorsListPage.AuthenticatorsListForm loginData={this.state.loginData} {...props}/> } />    
              <Route path='/adapters'       render = { props => <AdapterListPage.AdapterListForm loginData={this.state.loginData} {...props} /> } />    
              <Route path='/transformers'   render = { props => <TransformersListPage.TransformersListForm loginData={this.state.loginData} {...props} /> } />
              <Route path='/rmaker'         render = { props => <RecordMakerPage.RecordMakerForm loginData={this.state.loginData} {...props} /> } />
              <Route path='/specs'          render = { props => <SpecsListPage.SpecsListForm loginData={this.state.loginData} {...props} /> } />
              <Route path='/triggers'       render = { props => <TriggerListPage.TriggerListForm loginData={this.state.loginData} {...props} /> } />
              <Route path='/adapterstiles'  render = { props => <AdaptersTilesPage.AdaptersTilesForm loginData={this.state.loginData} {...props} /> } />
              <Route path='/setupbuilder'   render = { props => <SetupBuilderPage.SetupBuilderForm loginData={this.state.loginData} {...props} /> } />
              <Route path='/cljobs'         render = { props => <CLJobsPage.CLJobsForm loginData={this.state.loginData} {...props} /> } />
           */}   

            </Switch>
          </div>
          
          <br/>
          <br/>

      </Router>
      </div>
    );
  }

}

// ============== APP =====================

class SubNinjasApp extends React.Component {

  reset(){
    this.setState({"loginData": undefined});

  }

  render(){
    return(
      <Container>
          <img onClick={()=>this.reset()} src={banner}></img>
          <SubNinjasRouter env={this.props.env}/>
      </Container>
    )
  }

}

ReactDOM.render( 
  <div className="application">
      <meta charSet="utf-8" />
      <title>EasyAdapter Admin</title>
    <SubNinjasApp env=":dev"/>
  </div>,
  document.getElementById('root')
);