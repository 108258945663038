import React from 'react';
import ReactDOM from 'react-dom';

import * as lb from './lambda.js';
import './css/easyadapter.css';
import { Spinner } from "./spinner";
import {CopyToClipboard} from 'react-copy-to-clipboard';


import AWS from 'aws-sdk';
import { ua, au } from './ac';
AWS.config.region = 'eu-west-1';

export class SpecsListForm extends React.Component {


  lambdaMessageCallback(message, checkUser, checkChanged) {

    return (err, data)=>{

        if (err || data.FunctionError == "Unhandled"){ 
          this.props.history.push("/login"); 
          return; 
        }
        
        var output = JSON.parse(data.Payload);
        var body = JSON.parse(output.body);

        let bodyx = body.map(b => { 
          b.mapping = JSON.stringify(b.mapping); 
          b.specs = JSON.stringify(b.specs); 
          return b; 
        });
        
        this.setState({"apilist": bodyx, "apilistr": body});
    }

  }

  constructor(props) {

    super(props);

    this.state = {
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.lambdaMessageCallback = this.lambdaMessageCallback.bind(this);
    this.itemClicked = this.itemClicked.bind(this);

    if(this.props.loginData && this.props.loginData.userToken){
        AWS.config.credentials = au(this.props.loginData);
    } else {
        this.props.history.push("/login");  
    }

    lb.lambdaInvoke("EasyAdapter-Get" + this.props.loginData.env, {dataSet: "specslist", token: this.props.loginData.userToken}, this.lambdaMessageCallback());
  }

  handleChange(event) {
    this.setState({[event.target.id]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  itemClicked(item){
    this.setState({"jsontext": JSON.stringify(item).replaceAll("\\\"","\"")});
  }

  renderAPIListHeader(){
      return (
        <>
        <th>Id</th>
        <th>Owner</th>
        </>
      )
  }

  renderAPIListBody(){
      return this.state.apilist.sort((a,b)=>a.specid.localeCompare(b.specid)).map((item, key) => {
          return (
              <tr key={item.specid}>
                 <td><CopyToClipboard text={item.specid} onCopy={()=>this.setState({copied: true})}><button onClick={()=>{this.itemClicked(item)}}>{item.specid}</button></CopyToClipboard></td>
                 <td>{item.ownaccid}</td>
             
              </tr>                        
          ) 
      }); 
  }

  renderAPIList(){
      return(
        <div>
            <table className="table">
                <thead>
                    <tr>{ this.renderAPIListHeader() }</tr>
                </thead>
                <tbody>
                    { this.renderAPIListBody() }
                </tbody>  
            </table>
          </div>
      )  
  }

  render() {

    return (
 
      <div>
        <br/>
        <br/>
        <br/>
        <br/>
        
        { this.state.jsontext && this.state.jsontext != "" && (<div style={{"display":"inline-block", "marginTop":"10px", "marginBottom":"20px", "border": "1px dashed gray", "padding":"10px", "width":"75%", "overflowX":"scroll"}}>
          <label>{this.state.jsontext}</label>
        </div>)}

        <div>{this.state.apilist && this.renderAPIList()}</div>

        <div>

          {!this.state.apilist && (
                <Spinner
                  color={"black"}
                  style={{ height: "25px", margin: "10px" }}
                  className="spinner"
                />
          )}

        </div>

      </div>
    );
  }

}