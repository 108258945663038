import React from 'react';
import ReactDOM from 'react-dom';

import * as lb from './lambda.js';

import './css/easyadapter.css';
import './css/easyadapter-tiles.css';

import { Spinner } from "./spinner";

import configKeys from './json/module_config_keys.json';

import AddObjectSelector from './common/element.add-object-selector'

import AWS from 'aws-sdk';
import { ua, au } from './ac';
AWS.config.region = 'eu-west-1';

const newid = (x) => [...Array(x)].map(i=>(~~(Math.random()*36)).toString(36)).join('');

export class SetupBuilderForm extends React.Component {


  loadSelectItems(keytype, listObjName){

    console.log("Loading select items");

    this.setState({["pending_" + keytype]:true});

    let queryData = { 
      dataSet: configKeys[keytype].dataSet, 
      token: this.props.loginData.userToken 
    };

    try{
      lb.lambdaInvoke("EasyAdapter-Get" + this.props.loginData.env, queryData, (err, data) => {
        if (err || data == null) { this.props.history.push("/login"); return; }
        let pl = JSON.parse(data.Payload);
        let pb = JSON.parse(pl.body);
        let pklist = pb; 

        if (keytype == "SPC") {
          pklist = pklist.map(outerlist=>outerlist.configRecords.map(ol=>{ol.compositeKey = ol.type + ol.apid + ol.sequence; return ol}));
        }

        this.setState({ [listObjName]: pb });
        this.setState({ ["pending_" + keytype]: false });

      });
    } catch(e){
      this.props.history.push("/login");
    }

  }

  submitCommandObject(jsonspec, doafter) {

    this.setState({"busy": true});

    let lambda = new AWS.Lambda();

    AWS.config.credentials.get(()=>{

      try{

        var requestData = {
          token: this.props.loginData.userToken,
          secret: this.state.secret,    
          objectArray: JSON.stringify(jsonspec)
        }

        lambda.invoke({
            FunctionName: "EasyAdapter-Put" + this.props.loginData.env,
            Payload: JSON.stringify(requestData)
        }, (err,data)=>{

          this.setState({saving: false});

          if (err){

            let reporterror;

            if (err.toString().includes("AccessDeniedException")){
              reporterror = "Access denied. Are you logged in ?";
            } else {
              reporterror = "Error reported: " + err.toString().substring(0,200);
            }
            this.setState({reqerror: reporterror});
          } else {
              if (doafter && doafter.includes("SPC")) { this.loadSelectItems("SPC", "specList"); }
          }   
          
        });

      }
      catch(e){
        console.log(e);
        this.setState({"reqerror": e.toString().substring(0,200)});
      }

    });

  }

  constructor(props) {

    super(props);

    this.state = {
      valueRangeSelect: "",
      addNewRecordType: "outputMapping",
      addNewSequence: "0",
      addNewValue: "{\"value\":\"\"}",
      addNewSpecName: "",
      addNewAPI: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.deleteSpecClick = this.deleteSpecClick.bind(this);
    this.addNewSpecClick = this.addNewSpecClick.bind(this);

    try{    
      if(this.props.loginData && this.props.loginData.userToken){
          AWS.config.credentials = au(this.props.loginData);
      } else {
          this.props.history.push("/login");  
      }
    } catch{
          this.props.history.push("/login");  
    }
    
  }

  goLogin(){

     this.props.history.push("/login");  

  }

  componentDidMount(){
     this.loadSelectItems("API", "apiList");
     this.loadSelectItems("SPC", "specList");
  
  }

  handleChange(event) {

     this.setState({[event.target.id]: event.target.value});
  
  }

  handleSelectChange(e) {
     let {name, value} = e.target;
     this.setState({ [name]: value });
  }

  apiSelectChange(apiSelectboxValue){

      this.setState({"addNewAPI": apiSelectboxValue});

  }

  specSelectChange(specSelectboxValue){

    let editedSpec = this.state.specList.find(s=>s.specid==specSelectboxValue);
    this.setState({"editedSpec": editedSpec});

  }

  componentDidUpdate(p,s){
      if (this.state.editedSpec && s.editedSpec != this.state.editedSpec){
        this.setState({"sortedRecords": this.state.editedSpec.configRecords.sort((a,b)=> a.type.localeCompare(b.type) || a.sequence - b.sequence)}); 
      }

  }

  addRecordClick(){

      let addObject = { 
          type: this.state.addNewRecordType,
          sequence: this.state.addNewSequence,
          apid: this.state.addNewAPI,
          value: JSON.parse(this.state.addNewValue),
          compositeKey: this.state.addNewRecordType + this.state.addNewAPI + this.state.addNewSequence
      }


      let editedSpec = JSON.parse(JSON.stringify(this.state.editedSpec));
      editedSpec.configRecords = editedSpec.configRecords.filter(cf=>!(cf.type==this.state.addNewRecordType && cf.sequence == this.state.addNewSequence && cf.apid == this.state.addNewAPI));
      editedSpec.configRecords.push(addObject);
      editedSpec.configRecords = editedSpec.configRecords.sort((a,b)=>a.sequence - b.sequence && a.type - b.type);

      this.setState({"editedSpec": editedSpec});
      this.setState({"addNewSequence": Number(this.state.addNewSequence)+1});

  }
  
  deleteRecordClick(recordToDelete){

      let editedSpec = JSON.parse(JSON.stringify(this.state.editedSpec));
      editedSpec.configRecords = editedSpec.configRecords.filter(cf=>cf.compositeKey!=recordToDelete.compositeKey);

      this.setState({"editedSpec": editedSpec});

  }

  deleteSpecClick(){

      this.setState({"pending_SPC":true});

      let delObj = [{
        "del": { 
          "table": "spc",
          "key": this.state.editedSpec.specid, 
        }
      }]

      this.setState({"editedSpec": undefined});
      this.submitCommandObject(delObj, "SPC");

  }

  saveSpecClick(){

      this.setState({"pending_SPC":true});

      let saveObj = [{
        "spc":{
          ...this.state.editedSpec
        }
      }]

      this.submitCommandObject(saveObj, "SPC");
  
  }

  addNewSpecClick(){

      this.setState({"pending_SPC":true});

      let cmdObject = [{
        "spc": {
            "specid": this.state.addNewSpecName,
            "configRecords": []
        }
      }]

      this.submitCommandObject(cmdObject, "SPC");

  }

  cloneSpecClick(){

      this.setState({"pending_SPC":true});

      let cmdObject = [{
        "clo": {
            "specid": this.state.editedSpec.specid
        }
      }]

      this.submitCommandObject(cmdObject, "SPC");

  }

  applyAPIToAll(){
      
      var a = this.state.editedSpec.configRecords.map(hest=>{
        hest.apid = this.state.addNewAPI;
        return hest;
      });

      this.setState({"editedSpec.configRecords": a})

  }


  render() {

    return (
 
      <div>
        <br/>
        {/*<div>{JSON.stringify(this.state.specList)}</div>*/}

        <div style={{"display":"inline-block", "marginTop":"10px", "marginBottom":"10px", "width":"75%"}}>
          <h3>Specs Builder - {this.state.editedSpec && "-" && this.state.editedSpec.specid}</h3><hr/>
        </div>        

          <table style={{"width":"100%", "display": "inline-block"}}>
          <tbody>
          <tr>
          <td style={{"width": "200px"}}>
            <AddObjectSelector 
                pending={this.state.pending_SPC} 
                itemList={this.state.specList && this.state.specList.map(sl=>sl.specid)} 
                colorcss={configKeys["SPC"].colorcss}
                color={configKeys["SPC"].color}
                selName="specs"
                valueReporter={this.specSelectChange.bind(this)}
                hideAdd="true"
                sortKey="specid"
            />                 
          </td>
          

          <td><button disabled={!this.state.editedSpec} onClick={()=>{this.saveSpecClick()}} className={"button button-specs-editor"} style={{"margin":"10px", "marginTop": "3px"}}>Save</button>
              <button disabled={!this.state.editedSpec} onClick={()=>this.deleteSpecClick()} className={"button button-specs-editor"} style={{"margin":"10px", "marginTop": "3px"}}>Delete</button>
              
          </td><td>
              <button disabled={!this.state.editedSpec} onClick={()=>{this.cloneSpecClick()}} className={"button button-specs-editor"} style={{"margin":"10px", "marginTop": "3px"}}>Clone</button>
          </td>
          </tr><tr>
          <td>
                <input type="text" id="addNewSpecName" value={this.state.addNewSpecName} onChange={(e)=>{this.handleChange(e)}} className="setup-record-edit-box text-specs-editor form-control"/>
          </td>

          <td>
              <button onClick={()=>this.addNewSpecClick()} className={"button button-specs-editor"} style={{"margin":"10px", "marginTop": "3px"}}>Add New</button>
          </td>
                   
          </tr>

          <tr>
          <td>Record Type</td><td>API</td><td  style={{"paddingRight":"19px!important"}}>Apply all</td><td>Seq</td><td>Value</td>
          </tr>

          <tr>
          <td>   

              <select name="addNewRecordType" className={"select color-select color-select-add"} onChange={(e)=>this.handleSelectChange(e)}>
                <option value="authMapping">Authentication Mapping</option>      
                <option value="outputMapping">Output Mapping</option>
                <option value="pathParams">Path Params</option>
                <option value="queryParams">Query Params</option>                
                <option value="transformMapping">Transform Mapping</option>
              </select>     

          </td>
          <td>  
            <AddObjectSelector 
                pending={this.state.pending_API} 
                itemList={this.state.apiList && this.state.apiList.map(sl=>sl.apid)} 
                colorcss={configKeys["API"].colorcss}
                color={configKeys["API"].color}
                selName="addNewAPI"
                valueReporter={this.apiSelectChange.bind(this)}
                hideAdd="true"
                addLiteral="true"
                sortKey="apid"
               
            /> 
            </td><td>
            <button disabled={!this.state.editedSpec || !this.state.addNewAPI} onClick={()=>{this.applyAPIToAll()}} className={"button button-specs-editor"} style={{"margin":"10px", "marginTop": "3px"}}>All</button>

          </td>
          <td>
                <input type="text " id="addNewSequence" value={this.state.addNewSequence} onChange={(e)=>{this.handleChange(e)}} className="setup-record-edit-box-s form-control"/>
          </td>
          <td>
                <input type="text " id="addNewValue" value={this.state.addNewValue} onChange={(e)=>{this.handleChange(e)}} className="setup-record-edit-box form-control"/>
          </td>

          <td>
              <button disabled={!this.state.editedSpec || !this.state.addNewAPI} onClick={()=>{this.addRecordClick()}} className={"button button-specs-editor"} style={{"margin":"10px", "marginTop": "3px"}}>Add</button>
              
          </td>

          </tr>
          </tbody>
          </table>


          <br/>

        <table className="table"><tbody>
<tr>
          <td>Type</td>
          <td>Apid</td>
          <td>Sequence</td>
          <td>Value</td>
          <td>Delete</td>
</tr>
        

        {this.state.sortedRecords && (
            this.state.sortedRecords.map(cf=>
              <tr key={cf.compositeKey}>
              <td>{cf.type}</td>
              <td>{cf.apid}</td>
              <td>{cf.sequence}</td>
              <td>{JSON.stringify(cf.value)}</td>
              <td><button className="button color-select-button api-tile-inner" onClick={()=>this.deleteRecordClick(cf)}>Delete</button></td>
              </tr>
            )
        )}

        </tbody>
        </table>

      </div>
    );
  
  }

}

