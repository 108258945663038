import React from 'react';
import ReactDOM from 'react-dom';
import './css/easyadapter.css';
import { Spinner } from "./spinner";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import AWS from 'aws-sdk';
import { ua, au } from './ac';
AWS.config.region = 'eu-west-1';

const newid = (x) => [...Array(x)].map(i=>(~~(Math.random()*36)).toString(36)).join('');

export class AuthenticatorsListForm extends React.Component {

  isObject(obj){ return typeof(obj) == "object"; }

  lambdaCallback(err, data){
        
      if (err){
        this.props.history.push("/login"); 
        return; 
      } else {

        var dataPayload = JSON.parse(data.Payload);
        var data = JSON.parse(dataPayload.body);

        let datax = data.map(b=>{
          b.pathauth =   this.isObject(b.pathauth) ? JSON.stringify(b.pathauth) : b.pathauth;
          b.queryParamAuth =   this.isObject(b.queryParamAuth) ? JSON.stringify(b.queryParamAuth) : b.queryParamAuth;
          b.headersauth =  this.isObject(b.headersauth) ? JSON.stringify(b.headersauth) : b.headersauth;
          b.encrypted = b.encobj || b.encrypted == "Y" ? "Y" : "N";
          return b;
        });

        this.setState({"apilist": datax, "apilistr": data});
      }

  }

  lambdaGetList(callback){

    if (!AWS.config.credentials || AWS.config.credentials == null){
       this.props.history.push("/login");
       return;
    }

    AWS.config.credentials.get(()=>{

      let lambda = new AWS.Lambda();

      let pl = {
          dataSet: "authenticatorlist", 
          token: this.props.loginData.userToken,
          secret: this.state.secret        
      }

      lambda.invoke({
        FunctionName: "EasyAdapter-Get" + this.props.loginData.env,
        Payload: JSON.stringify(pl)
      }, callback);

      this.setState({"secret":""});

    });  

  }


  constructor(props) {

    super(props);

    this.state = {
        auid: "",
        uuid: "Unauthenticated",
        identityId: "Unauthenticated",
        description: "a",
        pathauth: "b",
        paramsauth: "c",
        headersauth: "d",
        secret: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.itemClicked = this.itemClicked.bind(this);

    if(this.props.loginData && this.props.loginData.userToken){
        AWS.config.credentials = au(this.props.loginData);
    } else {
      //this.setState({"notLoggedIn":true});
      this.props.history.push("/login");  
    }
   
    this.lambdaGetList(this.lambdaCallback.bind(this));

  }

  componentDidUpdate(p,s){
      this.state.uuid = this.props.loginData.userUid;
      this.state.identityId = this.props.loginData.identityId;
      this.state.token = this.props.loginData.userToken;
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  itemClicked(item){
    this.setState({"jsontext": JSON.stringify(item).replaceAll("\\\"","\"")});
  }

  renderAPIListHeader(){
      return (
        <>
        <th>Auth ID</th>
        <th>Encrypted</th>
        <th>Owner</th>
        <th>Description</th>
        <th>Path</th>
        <th>QueryParams</th>
        <th>Headers</th>        
        </>
      )
  }

  renderAPIListBody(){

      return this.state.apilist.sort((a,b)=>a.authid.localeCompare(b.authid)).map((item, key) => {
          return (
              <tr key={item.authid}>
                 <td><CopyToClipboard text={item.authid} onCopy={()=>this.setState({copied: true})}><button onClick={()=>{this.itemClicked(item)}}>{item.authid}</button></CopyToClipboard></td>
                 <td>{item.encrypted}</td>
                 <td>{item.ownaccid}</td>
                 <td>{item.description}</td>
                 <td>{item.pathauth}</td>
                 <td>{item.queryParamAuth}</td>
                 <td>{item.headersauth}</td>                                
              </tr>                        
          ) 
      }); 
  }

  renderAPIList(){
      return(
        <div>
            <table className="table">
                <thead>
                    <tr>{ this.renderAPIListHeader() }</tr>
                </thead>
                <tbody>
                    { this.renderAPIListBody() }
                </tbody>  
            </table>
          </div>
      )  
  }


  render() {

    return (


      <div>
         
        <br/>
        <br/>
        <br/>
        <br/>


        { this.state.jsontext && this.state.jsontext != "" && (<div style={{"display":"inline-block", "marginTop":"10px", "marginBottom":"20px", "border": "1px dashed gray", "padding":"10px", "width":"75%", "overflowX":"scroll"}}>
          <label>{this.state.jsontext}</label>
        </div>)}

        <div style={{"marginLeft":"6px"}}>
        <label>
          <input type="password" className="form-control" id= "secret" value={this.state.secret} onChange={this.handleChange} /><br/>
          Paste your secret here to view encrypted records
        </label>
        </div>
        <br/>

        <button onClick={()=>{
          this.lambdaGetList(this.lambdaCallback.bind(this));
        }}>Refresh</button>

        <br/>
        <br/>
        <div>{this.state.apilist && this.renderAPIList()}</div>

        <div>
          {!this.state.apilist && (
                <Spinner
                  color="#2a4a57"
                  style={{ height: "25px"}}
                  className="spinner"
                />
          )}
        </div>


      </div>
    );
  }

}